.form-container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    max-height: 1200px;
}

.payment-details {
    width: 90%;
    margin: 0 5%;
    padding-top: 5%;
    padding-bottom: 5%;
    background-color: #043220;
    box-shadow: 5px 5px 5px grey;
}

.group {
    width: 40%;
    margin: 0 5%;
    
}

.payment-form-title {
    text-align: center;
    color: white;
    margin-top: 8%;
}

.payment-form-top-title {
    text-align: center;
    color: white;
    margin: 0% 0 5%;
}

.country {
    width: 100%;
}

.country-dropdown {
    width: 100%;
}

.checkbox-container {
    width: 40%;
    margin: 3% 5% 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.checkbox {
    width: 1.8rem;
    height: 1.2rem;
}

.checkbox-label {
    font-size: 1.5rem;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    text-align: center;
}

.payment-total {
    color: white;
    margin-top: 5%;
    text-align: center;
}

.card-payment-container {
    height: 300px;
    
}

.discount-code-title {
    color: white;
    text-align: center;
}



.shipping-container {
    width: 75%;
    margin: 0 12.5%;
}

.ship-to-us {
    color: white;
    margin: 0;
    font-size: .8rem;
}

.billing-container {
    width: 75%;
    margin: 0 12.5%;
}

.shipping-notes {
    font-size: 1rem;
    width: 100%;
    height: 100px;
}

.sq-card-wrapper {
    color: white;
}

.sq-card-wrapper .sq-card-message-no-error {
    color: white;
}

.warning {
    color: white;
}

@media screen and (max-width: 800px) {

    .payment-details {
        width: 90%;
        margin: 0 5%;
    }

    .checkbox {
        height: .8rem;
        width: 1.2rem;
    }

    .checkbox-label {
        font-size: 1.2rem;
    }

    .checkbox-container {
        width: 100%;
        margin: 5% 0;
    }

    .card-payment-container {
        width: 90%;
        margin: 0 5%;
        height: auto;
    }

    .group {
        width: 80%;
        margin: 0 10%;
    }

    .payment-form-top-title {
        margin: 8% 0 14%;
    }

    .payment-total {
        margin-top: 10%;
    }

    .form-container {
        flex-wrap: nowrap;
        max-height: none;
    }
}
