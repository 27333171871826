.header {
    width: 100%;
    height: 15%;
    
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
}

.top-top-row {
    height: 30%;
}

.shipping-tag {
    width: 100%;
    margin: 0 0 0 20%;
    font-size: .9rem;
    color: white;
}

.top-row {
    width: 100%;
    height: 85%;
    padding-left: 3%;
   
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    top: 0;
    background-color: #043220;
    z-index: 10;
}

.bottom-row {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: space-between;
    background: #B7872D;
    align-content: center;
    
}

.logged-out {
    width: 20%;
    margin-right: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.logged-in {
    width: 25%;
    margin-right: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    
}

.logged-in-link {
    margin: 0 5% 0;
    color: #B7872D;
}

.link {
    color: white;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.9rem;
    width: 18%;
    display: flex;
    justify-content: center;
}

.logged-out-link {
    font-size: 1rem;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
   
}

.logged-in-link {
    font-size: .8rem;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    
}

.logo-link {
    height: 70%;
    margin: 15% 0;
    display: flex;
    justify-content: center;
}

.logo {
    height: 90%;
}

.scroll {
    width: 38%;
    margin-top: 0;
}

.cart-container {
    height: 100%;
    width: 15%;
    margin-right: 2%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.cart-img {
    height: 30%;
    padding-right: 10%;
 
}

.num-cart-items {
    font-size: 1.3rem;
    color: white;
}
