.phone-header {
    width: 100%;
    height: 8%;
    padding-left: 15%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position:fixed;
    z-index: 10;
    background-color: #043220;
}

.mountain-icon {
    height: 100%;
}

/*.title-container {
    margin-right: 9%;
} */

.title {
    color: white;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.5rem;
}

.phone-cart {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
}

.cart-img {
    height: 40%;
}
