ul {
    padding: 0;
}

li {
    list-style-type: none;
    text-align: center;
}

.product-info {
    color:#043220;
}

.product {
    width: 30%;
}

.thumb-img {
    width: 100%;
    max-height: 500px;
}

.add-to-cart {
    width: 60%;
    margin: 0 20%;
}

.add-to-cart-button {
    background-color: white;
    width: 100%;
    height: 10%;
    border: 2px solid #043220;
    border-radius: 5%/20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .product {
        width: 96%;
        margin: 0 2%;
    }

}
