.faq {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0 10% 0 10%;
}

.faq-titles {
    text-align: center;
    margin: 0;
}

.faq-content {
    text-align: center;
    margin-bottom: 5%;
}

.dosage-chart {
    width: 80%;
    margin: 0 10%;
}

.dosage-title {
    text-align: center;
    width: 96%;
    margin: 0 2%;
}

.testing {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin: 0 10%;
}

.testing-chart {
    width: 100%;
}

.test-button-text {
    text-decoration: underline;
    color: #B7872D;
}

.test-exit {
    width: 10%;
    margin-left: 90%;
}

.exit-button {
    margin: 0;
}

@media screen and (max-width: 800px) {
    .faq {
        width: 85%;
        margin-left: 7.5%;
        margin-right: 7.5%;
    }

    .testing-chart {
        width: 96%;
        margin: 0 2%;
    }

    .testing {
        width: 96%;
        margin: 0 2% 4%;
    }  

    .dosage-chart {
        width: 96%;
        margin: 0 2%;
    }
    .faq-content {
        margin-bottom: 12%;
    }
}
