.formRow {
    width: 100%;
    height: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2.5% auto;
}



.textArea {
    width: 100%;
    height: 100px;
    overflow-y: scroll;
}