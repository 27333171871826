.admin-container {
    width: 100%;
    height: 100%;
}

.actions {
    width: 50%;
    margin: 0 25%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center; 
}



.add-new-product-container {
    display: flex;
    flex-direction: column;
    background-color: #043220;
}

.add-new-post-container {
    display: flex;
    flex-direction: column;
    background-color: #043220;
}

.add-new-post-form {
    display: flex;
    flex-direction: column;
}

.add-new-product-form {
    display: flex;
    flex-direction: column;
    
}

.button-container {
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-content: space-around;
}

.manage-products-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (max-width: 800px) {
    .actions {
        width: 90%;
        margin: 0 5%;
    }
}
