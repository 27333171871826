.password-button {
    background-color: white;
    width: 40%;
    height: 10%;
    border: 2px solid #043220;
    border-radius: 5%/20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.password-input {
    width: 50%;
}