html,
body {
    margin: 0;
    padding: 0;
    background-color: white;
}

html,
body, 
#root,
.App,
.fullHeight {
  min-height: 100%;
  
}




*, ::before, ::after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

h1{
    font-size: 3rem;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #043220;
    text-align: center;
}

h2{
    font-size: 2rem;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #043220;
    margin: 2% 0;
}

h3{
    font-size: 1.3rem;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #043220;
    margin: 0;
    white-space: nowrap;
}

p {
    font-size: 1.2rem;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #043220;
    text-align: center;
}

a {
    text-decoration: none;
}

.fullHeight {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.phone-header-container {
    width: 100%;
    height: 10%;
    position: fixed;
    top: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.phone-header-container-top {
    height: 80%;
}

.phone-shipping-tag {
    width: 100%;
    height: 10%;
    margin: 8% 0 2%;
    font-size: .7rem;
    
    text-align: center;
}

.main {
    width: 100%;
    height: 100%;
    margin-top: 10%;
    
}

@media screen and (max-width: 800px) {
    .main {
        margin-top: 19%;
    }
}