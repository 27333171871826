.about-container {
    height: 100%;
    width: 100%;
    position: relative;
    margin-top: 5%;
}

.about-image-container {
    width: 100%;
}

.about-image {
    width: 75%;
    margin: auto 12.5%;
}

.about-content-container {
    width: 75%;
    margin: 1% 12.5% 5%;
    
    padding-bottom: 5%;
}

.bio-title {
    font-size: 5rem;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #043220;
    text-align: center;
    margin-bottom: 6%;
}

.bio-container {
    margin: 8% 0;
}

.bio-pic {
    width: 60%;
    margin: 0 20%;
}

.contact-container {
    width: 50%;
    margin: 0 25%;
}

.contact-button {
    background-color: #B7872D;
    width: 25%;
    margin: 0 37.5%;
    color: white;
    font-size: 1.2rem;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    border: none;
    border-radius: 5%/20%;
}

.email-link {
    color: #B7872D;
    text-decoration: underline;
}

.message-title {
    text-align: center;
}

@media screen and (max-width: 800px) {
    .about-content {
        font-size: 1.5rem;
    }

    .about-content-container {
        width: 85%;
        margin: 3% 7.5% 3%;
    }

    .about-image {
        width: 95%;
        margin: 0 2.5%;
    }

    .bio-title {
        font-size: 2.5rem;
    }

    .bio-pic {
        width: 100%;
        margin: 0;
    }

    .contact-container {
        width: 90%;
        margin: 0 5%;
    }
}
