.formWrap {
    width: 40%;
    height: 100%;
    margin: 5% 30% 10%;
}

.sign-in-form {
    height: 50%;
    width: 50%;
    margin: 0 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.formInput {
    width: 100%;
}

.login-button {
    background-color: white;
    width: 80%;
    height: 10%;
    border: 2px solid #043220;
    border-radius: 5%/20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.links {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-size: 1.2rem;
}

a {
    color: white;
}

@media screen and (max-width: 800px) {
    .formWrap {
        width: 100%;
        height: 100%;
        margin: 0;
    }

    .sign-in-form {
        width: 60%;
        margin: 0 15%;
    }
}