.mc__title {
    text-align: center;
}

.email-submit-button {
    background-color: white;
    width: 50%;
    height: 10%;
    margin: 0 25%;
    border: 2px solid #043220;
    border-radius: 5%/20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .email-submit-button {
        width: 75%;
    }
}
