.cart-items {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.full-width-checkout {
    width: 38%;
    display: flex;
    flex-direction: row;
}

.cart-thumb {
    width: 15%;
}

.product-name {
    width: 50%;
    white-space: normal;
}

.quantity-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50%;
}

.quantity-buttons {
    cursor: pointer;
}

.price-container {
    width: 15%;
}

.cart-button {
    width: 15%;
}

.remove-button {
    font-size: 1rem;
    color: #B7872D;
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .cart-items {
        flex-direction: column;
        width: 100%;
        height: 100%;
        align-items: center;
    }

    .cart-thumb {
        width: 90%;
        margin: 0 5%;
        
    }

    .product-name {
        width: 100%;
        font-size: 1rem;
        white-space: normal;
    }

    
    .quantity-container {
        width: 100%;
    }

    .price-container {
        width: 100%;
    }

    .cart-button {
        width: 100%;
        margin-top: 8%;
    }

    

    .remove-button {
        font-size: 2rem;
        color: #B7872D;
        text-decoration: underline;
        cursor: pointer;
    }
}
