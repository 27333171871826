.checkout-header {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.cart-titles {
    width: 15%;
    text-decoration: underline;
    text-align: center;
}

.cart-titles-spacer {
    width: 15%;
    color: white;
}

.checkout-button-container {
    width: 30%;
    margin: 0 35%;
}



.cart-actions {
    width: 60%;
}

.checkout-button {
    background-color: white;
    width: 100%;
    height: 10%;
    margin-top: 1%;
    border: 2px solid #043220;
    border-radius: 5%/20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    

    .checkout-flex-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .item-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .list-item-container {
        height: 100%;
        margin: 10% 0;
    }

    .list-item {
        height: 100%;
        

    }

    .total-container {
        width: 100%;
    }

    .total {
        width: 70%;
        margin: 0 15%;
        
    }

    .checkout-button-container {
        width: 100%;
        margin: 0;
    }
}
