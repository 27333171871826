.menu-container {
    position: fixed;
    top: 0;
    background-color: #043220;
    width: 100%;
    height: 100%;
    z-index: 50;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 10%;
}

.menu-links {
    color: white;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 1.9rem;
    width: 75%;
    display: flex;
    justify-content: center;
}

.menu-logged-in {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.menu-logged-out {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.menu-logged-out-link {
    font-size: 1rem;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    width: 30%;
    text-align: center;
   
}

.menu-logged-in-link {
    font-size: 1rem;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: white;
    width: 30%;
    text-align: center;
    
}
