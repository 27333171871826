.menu-icon {
    height: 6%;
    margin: 2% 2%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 15;
}

.icon {
    height: 75%;
    margin: 12.5% 12.5% 0 12.5%;
}