.products {
    background-color: white;
}

.products-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    
}

.shop-title {
    margin-top: 0;
    color: #043220;
}

@media screen and (max-width: 800px) {
    .products-container {
        display: flex;
        flex-direction: column;
        
        justify-content: space-around;
        margin-bottom: 10%;
    }
    .products {
        margin-top: 8%;
    }

    .shop-title {
        font-size: 2rem;
    }

}
