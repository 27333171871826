.product-card {
    width: 90%;
    margin: 5% 5% 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.carousel-container {
    width: 40%;
}

.product-img {
    width: 100%;
    max-height: 900px;
}

.product-details {
    width: 40%;
}

.price {
    font-size: 2rem;
}

.quantity {
    width: 3rem;
    height: 3rem;
}

.review-container {
    width: 100%;
    text-align: center;
}

.review-button {
    background-color: white;
    width: 40%;
    height: 10%;
    border: 2px solid #043220;
    border-radius: 5%/20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.review-post-container {
    width: 50%;
    margin: 0 25%;
}

.add-subscription-to-cart-button {
    background-color: white;
    width: 100%;
    height: 10%;
    border: 2px solid #043220;
    border-radius: 5%/20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

.sold-out {
    color: rgb(0, 17, 255);
}

@media screen and (max-width: 800px) {
    .product-card {
        flex-direction: column;
    }

    .carousel-container {
        width: 100%;
    }

    .product-details {
        width: 80%;
    }
}
