.modalOverlay {
    
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 100%;
    background-color: rgba(0,0,0,.3);
    z-index: 9999;
}

.modalWrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: white;
    width: 95%;
    padding: 2rem;
    max-width: 60rem;
    max-height: 75%;
    min-height: 40rem;
    pointer-events: all;
    overflow-y: scroll;
    
}