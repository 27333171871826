.background-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("https://drive.google.com/uc?id=1Buq3y8EfP5uaBXdENDAESx3mdnJenRwt");
    background-repeat: no-repeat;
    background-size: cover;
    background-blend-mode: darken;
    filter: blur(5px);
    -webkit-filter: blur(5px);
    z-index: 200;
}

.age-container {
    width: 50%;
    height: 50%;
    margin: 15% 25% 35%;
    background-color: white;
    position: fixed;
    z-index: 501;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    border: 3px solid #043220;
}

.age-button {
    background-color: white;
    width: 25%;
    height: 85%;
    margin-left: 37.5%;
    margin-right: 37.5%;
    border: 2px solid #043220;
    border-radius: 5%/20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

@media screen and (max-width: 800px) {
    .age-container{
        width: 75%;
        margin: 15% 12.5% 35%;
    }
}
