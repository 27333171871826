.home-container {
    position: relative;
    width: 100%;
    background-color: white;
}

.home-image {
    width: 30%;
    margin: 3% 35% 0;
}
.home-content-container {
    width: 100%;
    background-color: white;
}

.home-content {
    width: 75%;
    margin: 0% 12.5%;
    padding-top: 2%;
    padding-bottom: 5%;
    color: #043220;
    text-align: center;
}

.home-tag {
    text-align: center;
    color: #043220;
}

.image-container {
        min-height: 100%;
}

.news-title {
    font-size: 3rem;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #043220;
    text-align: center;
}

.shop-button {
    background-color: white;
    width: 30%;
    height: 10%;
    margin: 0 35%;
    border: 2px solid #043220;
    border-radius: 5%/20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}

@media screen and (max-width: 800px) {

    .image-container {
        margin-top: 10%;
        margin-bottom: 10%;
        width: 100%;
        height: 100%;
    }

    .home-content {
        font-size: 1.5rem;
        
        width: 85%;
        margin-left: 7.5%;
        margin-right: 7.5%;
    }

    .home-image {
        width: 90%;
        margin: auto 5%;
    }

    .home-tag {
        width: 90%;
        margin: 0 5%;
    }

    .shop-button {
        width: 80%;
        margin: 0 10%;
    }
}