.footer {
    width: 100%;
    
    z-index: 40;
    position: relative;
    bottom: 0;
    margin-top: 5%;
}

.footer-img {
    width: 100%;
    margin-bottom: 0;
   
    
}

.background-color-container {
    background-color: #B7872D;
}

.footer-link-container {
    
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.lower-footer-link-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 5%;
}

.footer-link {
    background-color: #B7872D;
    position: relative;
    z-index: 50;
    text-decoration: underline;
    width: 25%;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: .5%;
}

#cke_editor1 {
    width: 100%;
    margin: 0 20%;
}

.email-signup-container {
    width: 30%;
}

.email-signup-button {
    background-color: #043220;
    width: 50%;
    margin: 0 25%;
    height: 10%;
    border: none;
    border-radius: 5%/20%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
    font-size: 1.2rem;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    cursor: pointer;

}

.location-container {
    width: 30%;
}

.social-container {
    width: 30%;
}

.social-icon {
    width: 25%;
   
    margin-top: 4%;
    margin-bottom: 4%;
}

.social-links {
    width: 50%;
    margin: 0 25%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.disclaimer-container {
    font-size: .9rem;
    font-family: din-condensed, sans-serif;
    font-weight: 400;
    font-style: normal;
    color: #043220;
    text-align: center;
}



@media screen and (max-width: 800px) {

    .footer {
        margin-top: 25%;
    }

    .email-signup-container {
        width: 100%;
        margin-bottom: 3%;
    }

    .footer-link-container {
        flex-direction: column;
        width: 90%;
        margin: 0 5%;
    }

    .footer-link {
        width: 40%;
        font-size: .7rem;
        text-align: center;
    }

    .location-container {
        width: 100%;
    }

    .location {
        margin: 2% 0 6%;
    }

    .social-container {
        width: 100%;
    }

    .social-links {
        width: 60%;
        margin: 0 20% 5%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    .social-icon {
        width: 17%;
        height: 92%;
        margin-top: 4%;
        margin-bottom: 4%;
    }

    .social-title {
        margin-bottom: 0;
    }

    .disclaimer-container {
        width: 90%;
        margin: 0 5%;
        font-size: .75rem;
    }
}
