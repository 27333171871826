.email {
    text-align: center;
}

.total {
    text-align: right;
}

.shipping-address-container {
    text-align: left;
    width: 30%;
    margin: 0 35%;
}

.receipt-container {
    width: 90%;
    margin: 0 5%;
}
